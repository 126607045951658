<template>
  <div class="row m-1">
    <div class="col-xl-6">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                {{ managedKeys.SHIPPINGUPCHARGEMINDOLLAR.text }}
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-xl-8 col-md-12">
              <label for="text-account-no">Shipping Upcharge Value $ - (Max $1)</label>
              <dx-util-number-box
                v-model="shippingUpchargeMinDollar.value"
                format="$ #,##0.##"
                :max="0.5"
                styling-mode="filled"
              />
            </div>
            <div class="form-group col-xl-4 col-md-6">
              <label for="text-account-no" />
                <dx-util-button
                  icon="save"
                  text="Save"
                  :hint="`Save ${managedKeys.SHIPPINGUPCHARGEMINDOLLAR.text}`"
                  type="default"
                  styling-mode="contained"
                  :element-attr="btnElementAttr"
                  @click="createOrUpdateShippingUpchargeMinDollar"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 ">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                {{ managedKeys.SHIPPINGUPCHARGEPERCENT.text }}
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-xl-8 col-md-12">
              <label for="text-account-no">Shipping Upcharge Value % - (Max 5%)</label>
              <dx-util-number-box
                v-model="shippingUpchargePercent.value"
                :max="5"
                styling-mode="filled"
              />
            </div>
            <div class="form-group col-xl-4 col-md-6">
              <label for="text-account-no" />
                <dx-util-button
                  icon="save"
                  text="Save"
                  :hint="`Save ${managedKeys.SHIPPINGUPCHARGEPERCENT.text}`"
                  type="default"
                  styling-mode="contained"
                  :element-attr="btnElementAttr"
                  @click="createOrUpdateShippingUpchargePercent"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'

export default {
  data() {
    return {
      managedKeys: managedKeysEnum,
      shippingUpchargeMinDollar: {
        id: null,
        key: managedKeysEnum.SHIPPINGUPCHARGEMINDOLLAR.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
        valueType: valueTypeEnum.BIGDECIMAL.key,
        version: null,
        referenceId: null,
      },
      shippingUpchargePercent: {
        id: null,
        key: managedKeysEnum.SHIPPINGUPCHARGEPERCENT.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEFRONTEND.key,
        valueType: valueTypeEnum.INTEGER.key,
        version: null,
        referenceId: null,
      },
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  mounted() {
    this.getCurrentShippingUpchargeMinDollar()
    this.getCurrentShippingUpchargePercent()
  },
  methods: {
    // #region Shipping Up Charge Min Dollar
    getCurrentShippingUpchargeMinDollar() {
      tenantService.fetchByKey(managedKeysEnum.SHIPPINGUPCHARGEMINDOLLAR.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.shippingUpchargeMinDollar = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateShippingUpchargeMinDollar() {
      const shippingUpchargeMinDollar = { ...this.shippingUpchargeMinDollar }
      if (shippingUpchargeMinDollar.id) {
        tenantService
          .update(shippingUpchargeMinDollar)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.shippingUpchargeMinDollar = {
              ...this.shippingUpchargeMinDollar,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(shippingUpchargeMinDollar)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.shippingUpchargeMinDollar = {
              ...this.shippingUpchargeMinDollar,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    // #endregion
    // #region Shipping Upcharge Percent
    getCurrentShippingUpchargePercent() {
      tenantService.fetchByKey(managedKeysEnum.SHIPPINGUPCHARGEPERCENT.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.shippingUpchargePercent = {
            id: data.id,
            key: data.key,
            value: parseInt(data.value, 10),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateShippingUpchargePercent() {
      const shippingUpchargePercent = { ...this.shippingUpchargePercent }
      if (shippingUpchargePercent.id) {
        tenantService
          .update(shippingUpchargePercent)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.shippingUpchargePercent = {
              ...this.shippingUpchargePercent,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(shippingUpchargePercent)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.shippingUpchargePercent = {
              ...this.shippingUpchargePercent,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    // #endregion
  },
}
</script>

<style>

</style>
